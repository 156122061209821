console.log('dataLayer funcional');

// Componentes contato
const contactComponent = document.getElementsByClassName(
  'ContactUsCards__item-title'
);

// Disparador do evento
const contactBtnComponent = document.getElementsByClassName(
  'ContactUsCards__item-button'
);

// Adição do dataLayer no contato
for (i = 0; i < contactComponent.length; i++) {
  let contactText = contactComponent[i].innerHTML;
  contactBtnComponent[i].addEventListener('click', () =>
    dataLayer.push({ event: 'contact', element_text: contactText })
  );
}

// Wrapper com icones de rede social no footer
const socialBtnComponent = document.getElementById('menu-menu-social').children;
const socialBtnComponentMob = document.getElementById('menu-menu-social-1').children;

// Adição do dataLayer nos icones de rede social do footer
function AddDataLayerSocial(socialBtn){
  for (i = 0; i < socialBtn.length; i++) {
    const btnSocial = socialBtn[i].children[0];
    btnSocial.addEventListener('click', () =>
      dataLayer.push({ event: 'footer_button', element_text: btnSocial.title })
    );
  }
}

AddDataLayerSocial(socialBtnComponentMob)
AddDataLayerSocial(socialBtnComponent)

// Componentes telefone
const telComponent = document.getElementsByClassName('Footer-tel-number');

// Adição do dataLayer no Telefone
for (i = 0; i < telComponent.length; i++) {
  telComponent[i].addEventListener('click', () =>
    dataLayer.push({ event: 'footer_link', element_text: 'Telefone' })
  );
}

// componente user interaction
const contOnline = document.getElementsByClassName('CardPlan-link');
const contWhats = document.getElementsByClassName('ButtonWhats');

//push do user interaction
function PushDataInteraction(component) {
  for (let i = 0; i < component.length; i++) {
    component[i].addEventListener('click', () =>
      dataLayer.push({
        event: 'user_interaction',
        element_text: component[i].innerHTML,
      })
    );
  }
}

PushDataInteraction(contOnline);
PushDataInteraction(contWhats);

// dataLayer header_menu
const headerMenu = document.getElementById('menu-menu-cabecalho-1').children;

for (let i = 0; i < headerMenu.length; i++) {
  if (!headerMenu[i].classList.contains('btn-menu')) {
    headerMenu[i].addEventListener('click', () =>
      dataLayer.push({
        event: 'header_menu',
        element_text: headerMenu[i].children[0].innerHTML,
      })
    );
  }
}

// Datalayer plan_menu
const selectPlanBtn = document.getElementsByClassName('controllerButton');

for (let i = 0; i < selectPlanBtn.length; i++) {
  selectPlanBtn[i].addEventListener('click', () =>
    dataLayer.push({
      event: 'plan_menu',
      element_text: selectPlanBtn[i].innerHTML,
    })
  );
}

// //view_item dataLayer
// const CardPlan = document.getElementsByClassName('CardPlan-wrapper')
// console.log(CardPlan, 'cardplan')
// for(let i = 0; i < CardPlan.length; i++){

//   CardPlan[i].addEventListener('mouseenter', (e)=>{
//     const data = e.target
//     console.log(data)
//     dataLayer.push({
//       currency: "BRL",
//       value: 'valor do item',

//       items: [{
//         item_id: 'id',
//         item_name: 'nome do item',
//         item_category: 'categoria do item',
//         item_list_id: 'id',
//         item_list_name: 'nome da lista do item',
//         price: 'preço do produto',
//         quantity: 'quantidade',
//         coupon: 'cupom de desconto'
//       }]
//     })
//   })
// }

// data layer banner_button

const banner = document.getElementsByClassName('Hero--content')[0].children;

for (let i = 0; i < banner.length; i++) {
  if (banner[i].classList.contains('Button')) {
    banner[i].addEventListener('click', () =>
      dataLayer.push({
        event: 'banner_button',
        element_text: banner[i].textContent,
      })
    );
  }
}

